import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Subheader from '../../../components/Header/navmap/repairShop';
import sitemap from '../../../data/sitemap';
import Pricing from './Pricing';
import Clients from './Clients'

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.industries}
    subpage={sitemap.repairShop.priceTable}
    subheader={Subheader}
    url='_url:repair-shop-pricing-tables'>
    <SEO {...seo} location={location}/>
    <Pricing/>
    <Clients/>
  </Layout>
);