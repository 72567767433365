import React from 'react';
import Content from '../views/RepairShop/Pricing';

export default ({location}) => (
  <Content location={location} seo={{
    title: 'Vehicle Care - bliv vores værkstedspartner',
    lang: 'da-DK',
    description: 'Få alle fordelene ved at blive vores værkstedspartner! Bliv altid informeret om tilstanden på hvert tilsluttet køretøj og vær tættere på hver kunde.',
    meta: [{
      name: 'keywords',
      content: 'Værkstedspartner'
    }]
  }}/>
);